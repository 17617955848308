'use client';

import type { BrowserAuthStrategy } from '../types.d';

export { AuthProvider } from './AuthProvider';
import { getBrowserAccessToken, getBrowserIdToken, refreshAllSessionTokens, signOut } from './auth-methods';
import { disable2fa, enable2fa, getSetupCodeFor2fa, is2faEnabled } from './2fa';
import { activityDetected, checkActivity, setInitialActivity } from './sessionUtils';

export const BrowserAuth: BrowserAuthStrategy = {
  Session: {
    getBrowserAccessToken,
    getBrowserIdToken,
    refreshAllSessionTokens,
    signOut,
  },
  TwoFA: {
    disable2fa,
    is2faEnabled,
    getSetupCodeFor2fa,
    enable2fa,
  },
  ActivityMonitor: {
    setInitialActivity,
    activityDetected,
    checkActivity,
  },
};
