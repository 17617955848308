export const Env = {
  application: {
    domain: `https://${process.env.NEXT_PUBLIC_DOMAIN}`,
    endpoint: `https://${process.env.VERCEL_URL || 'app.iofinnet.com'}`,
    gitCommit: process.env.VERCEL_GIT_COMMIT_SHA || '',
    logoutTime: process.env.NEXT_PUBLIC_INACTIVITY_LOGOUT_TIME
      ? Number(process.env.NEXT_PUBLIC_INACTIVITY_LOGOUT_TIME)
      : undefined,
  },
  gql: {
    endpoint: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT!,
  },
  //**TODO: Remove this once feature is done */
  features: {
    vaultStatements: process.env.FEATURE_FLAG_VAULT_STATEMENTS === 'true',
    billing: process.env.FEATURE_FLAG_BILLING === 'true',
  },
  cognito: {
    region: process.env.NEXT_PUBLIC_COGNITO_REGION!,
    poolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID!,
    clientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID!,
  },
  analytics: {
    vercel: {
      enabled: parseBoolean(process.env.ENABLE_ANALYTICS_VERCEL, 'ENABLE_ANALYTICS_VERCEL'),
    },
    fullstory: {
      enabled: parseBoolean(process.env.ENABLE_ANALYTICS_FULLSTORY, 'ENABLE_ANALYTICS_FULLSTORY'),
    },
    pendo: {
      enabled: parseBoolean(process.env.ENABLE_ANALYTICS_PENDO, 'ENABLE_ANALYTICS_PENDO'),
      apiKey: process.env.ANALYTICS_PENDO_API_KEY || null,
    },
  },
  chargebee: {
    site: process.env.CHARGEBEE_SITE!,
    apiKey: process.env.CHARGEBEE_API_KEY!,
    freemiumVaultPlan: 'iovault-Freemium-USD-Monthly',
    vaultItemFamily: 'iovault',
  },
  vaults: {
    minThreshold: Number.parseInt(process.env.NEXT_PUBLIC_VAULTS_MIN_THRESHOLD ?? '', 10),
    maxThreshold: Number.parseInt(process.env.NEXT_PUBLIC_VAULTS_MAX_THRESHOLD ?? '', 10),
  },
  walletConnect: {
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID!,
  },
  termsAndConditions: {
    platformVersion: Number.parseInt(process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS_PLATFORM_VERSION ?? '1'),
    networkUCVersion: Number.parseInt(process.env.NEXT_PUBLIC_TERMS_AND_CONDITIONS_NETWORK_UC_VERSION ?? '1'),
  },
  links: {
    app: 'https://app.iofinnet.com',
    iosAppStore: process.env.NEXT_PUBLIC_IOS_APP_URL ?? 'https://apps.apple.com/app/io-vault/id1641136546',
    androidAppStore:
      process.env.NEXT_PUBLIC_ANDROID_APP_URL ??
      'https://play.google.com/store/apps/details?id=com.iofinnet.vault&pli=1',
    mpcLearnMore: 'https://support.iofinnet.com/helpcenter/iov/introduction',
    privacyPolicy: 'https://www.iofinnet.com/products-privacy-policy',
    termsAndConditionsPMorUC: 'https://www.iofinnet.com/',
    termsAndConditionsVault: 'https://www.iofinnet.com/iovault-terms-and-conditions',
    termsAndConditionsFlow: 'https://www.iofinnet.com/terms-of-service-ioflow',
    britanniaTermsAndConditions: 'https://www.iofinnet.com/britannia-network-terms-and-conditions',
    britanniaPrivacyPolicy: 'https://www.iofinnet.com/britannia-network-privacy-policy',
    helpCenter: 'https://support.iofinnet.com/helpcenter/io-vault',
    termsAndConditionsPlatform: 'https://www.iofinnet.com/iovault-terms-and-conditions',
    onChainSupport: 'https://support.iofinnet.com/helpcenter/iov/on-chain-questions',
    submitHelpRequest: 'https://support.iofinnet.com/helpcenter/kb-tickets/new',
    apiReference: 'https://docs.iofinnet.com/reference/core-introduction',
    platformFAQ: 'https://support.iofinnet.com/helpcenter/io-vault#faqs',
    vaultPricing: 'https://www.iofinnet.com/io-vault-prices',
  },
} as const;

if (!Env.gql.endpoint) {
  throw new Error(`Environment variable "NEXT_PUBLIC_GRAPHQL_ENDPOINT" was not provided`);
}

if (!Env.cognito.region) {
  throw new Error(`Environment variable "NEXT_PUBLIC_COGNITO_REGION" was not provided`);
}

if (!Env.cognito.poolId) {
  throw new Error(`Environment variable "NEXT_PUBLIC_COGNITO_POOL_ID" was not provided`);
}

if (!Env.cognito.clientId) {
  throw new Error(`Environment variable "NEXT_PUBLIC_COGNITO_CLIENT_ID" was not provided`);
}

if (Number.isNaN(Env.vaults.minThreshold) || Env.vaults.minThreshold <= 0) {
  throw new Error(
    `Environment variable "NEXT_PUBLIC_VAULTS_MIN_THRESHOLD" was not provided or is not a positive number`,
  );
}

if (Number.isNaN(Env.vaults.maxThreshold) || Env.vaults.maxThreshold <= 0) {
  throw new Error(
    `Environment variable "NEXT_PUBLIC_VAULTS_MAX_THRESHOLD" was not provided or is not a positive number`,
  );
}

if (Env.vaults.minThreshold >= Env.vaults.maxThreshold) {
  throw new Error(
    `Environment variable "NEXT_PUBLIC_VAULTS_MAX_THRESHOLD" should be a positive number higher than "NEXT_PUBLIC_VAULTS_MIN_THRESHOLD"`,
  );
}

if (!Env.walletConnect.projectId) {
  throw new Error(`Environment variable "NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID" was not provided`);
}

function parseBoolean(value: string | undefined, name: string): boolean {
  if (value === 'true' || value === '1') {
    return true;
  }

  if (value === undefined || value === '' || value === '0' || value === 'false') {
    return false;
  }

  throw new Error(`Invalid value for "${name}": "${value}"`);
}
