import { Authenticator } from '@aws-amplify/ui-react'; // eslint-disable-line no-restricted-imports

import '@aws-amplify/ui-react/styles.css'; // eslint-disable-line import/no-internal-modules

// eslint-disable-next-line import/no-internal-modules
import { configureAmplify } from '../amplify-core';

configureAmplify();

export function AuthProvider({ children }: { children: React.ReactNode }) {
  return <Authenticator.Provider>{children}</Authenticator.Provider>;
}
