import { Auth } from 'aws-amplify'; // eslint-disable-line no-restricted-imports

import { PREFERRED_MFA } from '../preferred-mfa';

export async function disable2fa({ oneTimePassword }: { oneTimePassword: string }): Promise<void> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.verifyTotpToken(user, oneTimePassword);
    await Auth.setPreferredMFA(user, 'NOMFA');
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }

    throw new Error(err as any);
  }
}

export async function enable2fa({ oneTimePassword }: { oneTimePassword: string }): Promise<void> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.verifyTotpToken(user, oneTimePassword);
    await Auth.setPreferredMFA(user, PREFERRED_MFA);
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }

    throw new Error(err as any);
  }
}

export async function is2faEnabled(): Promise<boolean> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const preferredMfa = await Auth.getPreferredMFA(user, { bypassCache: false });
    return preferredMfa === PREFERRED_MFA;
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }

    throw new Error(err as any);
  }
}

export async function getSetupCodeFor2fa(): Promise<{ plainCode: string; qrData: string }> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const code = await Auth.setupTOTP(user);

    const appName = encodeURI(getAppName());
    const email = encodeURI(user.attributes.email);

    return {
      plainCode: code,
      qrData: `otpauth://totp/${appName}:${email}?secret=${code}`,
    };
  } catch (err) {
    if (err instanceof Error) {
      throw err;
    }

    throw new Error(err as any);
  }
}

function getAppName() {
  if (location.host === 'app.iofinnet.com') {
    return 'io.finnet';
  }

  const [, env = 'dev'] = location.host.match(/\.([^.]+)\.io(dev|fin)net\.com$/) ?? [];
  return `io.finnet (${env})`;
}
