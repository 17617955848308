import { Auth } from 'aws-amplify'; // eslint-disable-line no-restricted-imports
import type { CognitoUser } from 'amazon-cognito-identity-js';

// eslint-disable-next-line import/no-internal-modules
import { logger } from './logger';

function clearUserFromLocalStorage(): void {
  for (const item in localStorage) {
    if (item.startsWith('CognitoIdentity') || item.startsWith('USER_CONFIG')) {
      localStorage.removeItem(item);
    }
  }
  localStorage.removeItem('lastUserActivity');
}

export async function signOut(): Promise<void> {
  logger.debug('will sign out');
  await Auth.signOut();
  clearUserFromLocalStorage();
  logger.info('signed out');

  if (typeof location !== 'undefined') {
    logger.info('will redirect back to /auth');
    location.href = '/auth';
  }
}

export async function getBrowserIdToken(): Promise<string | null> {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch {
    return null;
  }
}

export async function getBrowserAccessToken(): Promise<string | null> {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch {
    return null;
  }
}

export async function refreshAllSessionTokens(): Promise<void> {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = user.getSignInUserSession();

  if (!currentSession) {
    throw new Error('No user session found');
  }

  const refreshPromise = new Promise<void>((resolve, reject) => {
    user.refreshSession(currentSession.getRefreshToken(), (err) => {
      if (err) {
        return reject(err);
      }

      resolve();
    });
  });

  return refreshPromise;
}
